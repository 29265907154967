import { ref } from "vue";

import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import { createId, Nullable } from "@tager/admin-services";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type BannerLandingFormValues = {
  bannerTitle?: string;
  bannerSubtitle?: string;
  bannerDesktop?: Nullable<SingleFileInputValueType>;
  bannerMobile?: Nullable<SingleFileInputValueType>;
};

export const convertBannerValueToFormValues = (
  data: any
): BannerLandingFormValues => ({
  bannerTitle: data.bannerTitle,
  bannerSubtitle: data.bannerSubtitle,
  bannerDesktop: data.bannerDesktop
    ? { id: createId(), file: data.bannerDesktop }
    : null,
  bannerMobile: data.bannerMobile
    ? { id: createId(), file: data.bannerMobile }
    : null,
});

export type BannerLandingValue = {
  bannerTitle?: string;
  bannerSubtitle?: string;
  bannerDesktop?: string | null;
  bannerMobile?: string | null;
  bannerParams?: Array<{
    image: string | null;
    text: string;
  }>;
};

export const convertBannerFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  bannerParamsField: RepeaterField
): BannerLandingValue => ({
  bannerTitle: formValues.bannerTitle || "",
  bannerSubtitle: formValues.bannerSubtitle || "",
  bannerDesktop: formValues.bannerDesktop?.file.id || null,
  bannerMobile: formValues.bannerMobile?.file.id || null,
  bannerParams: bannerParamsField.value.map((item) => ({
    image: item.value[0].value ? (item.value[0].value as any).file.id : null,
    text: String(item.value[1].value),
  })),
});

export const BannerParamsFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Параметры",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "image",
      type: "IMAGE",
      label: "Иконка",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "text",
      type: "STRING",
      label: "Текст",
    },
  ],
};

export const createBannerParamsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      BannerParamsFieldConfig,
      data.bannerParams?.map((item: any) => [
        {
          name: "image",
          value: item.image,
        },
        {
          name: "text",
          value: item.text,
        },
      ]) || []
    )
  );
