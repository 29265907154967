import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type PlusesLandingFormValues = {
  plusesTitle?: string;
  plusesText?: string;
};

export const convertPlusesValueToFormValues = (
  data: any
): PlusesLandingFormValues => ({
  plusesTitle: data.plusesTitle,
  plusesText: data.plusesText,
});

export type PlusesLandingValue = PlusesLandingFormValues & {
  plusesItems?: Array<{
    title: string;
    text: string;
    image: string | null;
  }>;
};

export const convertPlusesFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  plusesItemsField: RepeaterField
): PlusesLandingValue => ({
  plusesTitle: formValues.plusesTitle,
  plusesText: formValues.plusesText,
  plusesItems: plusesItemsField.value.map((item) => ({
    title: String(item.value[0].value),
    text: String(item.value[1].value),
    image: item.value[2].value ? (item.value[2].value as any).file.id : null,
  })),
});

export const PlusesItemsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Элементы",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "TEXT",
      label: "Текст",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка",
      meta: {
        scenario: "generation-landing",
      },
    },
  ],
};

export const createPlusesItemsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      PlusesItemsConfig,
      data.plusesItems?.map((item: any) => {
        return [
          {
            name: "title",
            value: item.title,
          },
          {
            name: "text",
            value: item.text,
          },
          {
            name: "image",
            value: item.image,
          },
        ];
      }) || []
    )
  );
