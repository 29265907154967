import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import { SingleFileInputValueType } from "@tager/admin-ui";
import { createId } from "@tager/admin-services";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type InteriorLandingFormValues = {
  interiorTitle?: string;
  interiorTitle2?: string;
  interiorText?: string;
  interiorGallery?: Array<SingleFileInputValueType>;
};

export const convertInteriorValueToFormValues = (
  data: any
): InteriorLandingFormValues => ({
  interiorTitle: data.interiorTitle,
  interiorTitle2: data.interiorTitle2,
  interiorText: data.interiorText,
  interiorGallery: data.interiorGallery
    ? data.interiorGallery.map((item: any) => ({
        id: createId(),
        file: item,
      }))
    : [],
});

export type InteriorLandingValue = {
  interiorTitle?: string;
  interiorTitle2?: string;
  interiorText?: string;
  interiorGallery?: string[];
  interiorFeatures?: Array<{
    name: string;
    image: string | null;
  }>;
};

export const convertInteriorFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  interiorFeaturesField: RepeaterField
): InteriorLandingValue => ({
  interiorTitle: formValues.interiorTitle || "",
  interiorTitle2: formValues.interiorTitle2 || "",
  interiorText: formValues.interiorText || "",
  interiorGallery:
    formValues.interiorGallery?.map((item) => item.file.id) || [],
  interiorFeatures: interiorFeaturesField.value.map((item) => ({
    name: String(item.value[0].value),
    image: item.value[1].value ? (item.value[1].value as any).file.id : null,
  })),
});

export const InteriorFeaturesConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Преимущества",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "name",
      type: "STRING",
      label: "Название",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка",
      meta: {
        scenario: "generation-landing",
      },
    },
  ],
};

export const createInteriorFeaturesField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      InteriorFeaturesConfig,
      data.interiorFeatures?.map((item: any) => {
        return [
          {
            name: "name",
            value: item.name,
          },
          {
            name: "image",
            value: item.image,
          },
        ];
      }) || []
    )
  );
